<template>
    <div class="page-content" v-if="item">
        <page-breadcrumb title="Exam Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
            <b-card class="mt-1">
                <form-generator :model="item" :schema="item_form_schema" />
                <div v-if="item.type == 1">
                    <h4>Recommend Courses</h4>
                    <dynamic-zone ref="map_list" item-collapse="last"
                        :items="item.recommend_courses"
                        title_field="title"
                        :default-data="{ title: '', description: translatableDefaultObject }"
                        :enable-create-item="true"
                        >
                        <template v-slot="{ item }">
                            <form-generator :model="item"
                                :schema="[
                                    { cols: 12, fields: [{ field: 'title', label: 'Title', validate: { required: true } }] },
                                    { cols: 12, fields: [{ field: 'description', label: 'Description', input_type: 'ui-component' }] },
                                ]"
                            />
                        </template>
                    </dynamic-zone>
                </div>
            </b-card>
        </section>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>
</template>
<script>
const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Type', field: 'type', input_type: 'select', options:[
        {text: "Entry test", value: 1},
        {text: "Từ vựng", value: 2},
		{text: "Nghe", value: 3},
		{text: "Tương tác nghe nói", value: 4}

	  ], disabled: true},
      { label: 'Tag IS', field: 'completed_tag', input_type: 'number', validate: { type: Number } },
      { label: 'Level', field: 'title', input_type: 'ui-component', validate: { required: true } },
      { label: 'Level Text', field: 'level_text', input_type: 'ui-component', validate: { required: true }},
      { label: 'Min Score', field: 'min_score', input_type: 'nummer', validate: { required: true } },
      { label: 'Max Score', field: 'max_score', input_type: 'nummer', validate: { required: true } },
      { label: 'What you can do Title', field: 'what_can_do_title', input_type: 'ui-component', validate: { required: true }},
      { label: 'What you can do Content', field: 'what_can_do_content', input_type: 'ui-component', validate: { required: true }},
      { label: 'Next Step Title', field: 'next_step_title', input_type: 'ui-component', validate: { required: true }},
      { label: 'Next Step Content', field: 'next_step_content', input_type: 'ui-component', validate: { required: true }},
    ]
  }
];
const translatableDefault = {
  type: 'translatable-richtext',
  meta: {
    default_lang: 'vi'
  },
  value: '',
  translations: [
    {
      language: 'vi',
      value: ''
    }
  ]
}
import service from '../service'
export default {
    data(){
        return{
            item_form_schema,
            item: null,
            isUpdating: false,
            isDeleting: false,
            translatableDefaultObject: JSON.parse(JSON.stringify(translatableDefault))
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Exam Levels',
                    to: { name: 'goga-exam-level-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created() {
        this.getExamLevel()
    },
    methods: {
        async getExamLevel(){
            this.item = await service.get({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            await service.update({
                data: JSON.stringify(this.item),
            });
            this.getExamLevel();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "goga-exam-level-list" });
        },
    }
}
</script>
